/* eslint eqeqeq: "off" */
import React, { useState, useEffect } from "react"
import { isEqual } from "lodash"
import Layout from "../ui/Layout"
import connectStore from "../state/connectStore"
import OrderStatusCard from "../ui/OrderStatusCard"
import { fetchAllOrderStatus } from "../util/OrderUtil"
import {
  Divider,
  Container,
  Card,
  Message,
} from "semantic-ui-react"

const DISPLAY_MAX = 10;
const FINISHED_MAX = 4;
const OLDNESS_MAX = 60*5; 

const Page = ({ location, order }) => {
  const [orders, setOrders] = useState([]);
  const [poke, setPoke] = useState(0);

  useEffect(() => {
    const timer = setTimeout(() => {
      fetchAllOrderStatus(fetchedOrders => {
        if(!isEqual(fetchedOrders, orders)) {
          setOrders(fetchedOrders);
        } else {
          setPoke(poke+1);
        }
      });
    }, poke == 0 ? 0 : 10000);
    return () => clearTimeout(timer);
  });

  const now = Date.now() / 1000;
  let workingOrders = orders.filter(order => order.finishedCookingAt == null || order.finishedCookingAt >= now).sort((ordA, ordB) => ordA.submittedOrderAt - ordB.submittedOrderAt);
  let finishedOrders = orders.filter(order => order.finishedCookingAt != null && order.finishedCookingAt < now).sort((ordA, ordB) => ordA.submittedOrderAt - ordB.submittedOrderAt);

  // Debug
  /*if(workingOrders.length > 0) {
    for(let i = 0; i < 10; i++) {
      workingOrders.push(workingOrders[0]);
    }
  }*/

  // First prune any orders older than a certain time
  const then = now - OLDNESS_MAX;
  finishedOrders = finishedOrders.filter(order => order.finishedCookingAt >= then);

  // We might have more orders than we can show, so we need to decide what we want
  if(finishedOrders.length > FINISHED_MAX) {
    finishedOrders = finishedOrders.slice(finishedOrders.length - FINISHED_MAX, FINISHED_MAX);
  }
  if(workingOrders.length + finishedOrders.length > DISPLAY_MAX) {
    const workingOrderMax = DISPLAY_MAX - finishedOrders.length;
    workingOrders = workingOrders.slice(0, workingOrderMax);
  }
 
  const onFinished = () => setPoke(poke+1);

  return (
    <Layout location={location} to="/order-complete" validate={false} showBack={false} showMenu={false} showOrder={false} header={<div style={{ fontSize: "2.5em", marginTop: "0.5em", marginBottom: "0.5em" }}>Order Progress</div>}>
      <Container textAlign="center">
        <Card.Group itemsPerRow={1}>
        {finishedOrders.length == 0 && workingOrders.length == 0 && <Message style={{ width: "100%", marginTop: "1em", fontSize: "3em" }}>Currently No Orders</Message>}
        {finishedOrders.map((ord, i) => (
          <OrderStatusCard key={i} order={ord} showToppings index={i}/>
        ))}
        </Card.Group>
        <Divider hidden/>
        <Divider hidden/>
        {/*<Divider/>*/}
        <Divider hidden/>
        <Card.Group itemsPerRow={1}>
        {workingOrders.map((ord, i) => (
          <OrderStatusCard key={i} order={ord} showToppings index={i} onFinished={onFinished}/>
        ))}
        </Card.Group>
      </Container>
      <Divider hidden/>
      <Divider hidden/>
    </Layout>
  );
};

export default connectStore(Page);
