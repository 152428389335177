/* eslint eqeqeq: "off" */
import React from "react"
import { getImage, toDisplaySlot, getOrderToppings } from "../util/OrderUtil"
import OrderCountDown from "./OrderCountDown"
import Color from "../style/Color"

import {
  Card,
  Segment,
  Grid,
  Icon,
  Label,
  Image,
} from "semantic-ui-react"

const bannerStyleProp = {
  minWidth: "50px",
  width: "110%", /* width of container */
  objectFit: "cover",
  /*borderRadius: "4px 0px 40px 0px",
  WebkitBorderRadius: "4px 0px 40px 0px",
  MozBorderRadius: "4px 0px 40px 0px",*/
  transform: "translateX(-1em)",
  float: "left",
};

const nameStyle = { 
  wordBreak: "break-all",
  fontSize: "4em",
};

const ToppingSummary = ({ order }) => {
  if(order == null) {
    return null;
  }

  const images = [];
  getOrderToppings(order, "dry").forEach(t => images.push(getImage(t.name)));
  getOrderToppings(order, "wet").forEach(t => {
    images.push(getImage(t.name));
    if(t.extra) {
      images.push(getImage(t.name));
    }
  });

  return (<>
    {images.map((image, i) => (
      <Image
        key={i}
        circular
        size="small"
        className="topping-summary-icon"
        style={{
          transform: "translateX(" + 80*i + "%)",
        }}
      >
        {image}
      </Image>
    ))}
  </>);
};


const OrderStatusCard = ({ order, showToppings=true, index, onFinished=null }) => {
  let { cookie, finishedCookingAt, pickupSlot } = order;
  const cookieImage = getImage(cookie, {
    style: bannerStyleProp,
  });

  if(cookieImage == null) {
    return (
      <Card
        centered
        raised
      >
      No Cookie
      </Card>
    );
  }

  const now = Date.now()/1000;
  const finished = finishedCookingAt != null && (finishedCookingAt <= now);
  const color = finished ? Color.accent : Color.plain;

  const nameComp = (
    <>
      <div style={nameStyle} color={color}>
        <Icon name={finished ? "user" : "user outline"} color={color}/>
        {order.customerName}
      </div>
      {finished && pickupSlot != null && <Label style={{ transform: "translateX(0.88em) translateY(1.3em)", fontSize: "4em" }} floating circular color={color} size="big">{toDisplaySlot(pickupSlot)}</Label>}
    </>
  );

  return (
    <Segment raised style={{ width: "100%" }}>
      <Grid container stretched>
        <Grid.Column width={4}>
          {cookieImage}
          {showToppings && 
            <ToppingSummary order={order}/>
          }
          {finished &&
            <Label style={{ position: "absolute", left: "-1.0em", top: "0.5em", fontSize: "4em" }} circular color={color}>
              {toDisplaySlot(pickupSlot)}
            </Label>
          }
        </Grid.Column>
        <Grid.Column width={12}>
          <OrderCountDown order={order} color={color} onFinished={onFinished} nameLabel={nameComp}/>
        </Grid.Column>
      </Grid>
    </Segment>
  );
};

export default OrderStatusCard;
